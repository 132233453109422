import { axios, base_url } from "./imports";

const forceTranslationsApi = "https://biochapi.personalwaiter.it/translatesync";

export async function login(obj) {
    try {
        const response = await axios.post(base_url + "adminLogin", obj);
        return response.data;
    } catch (error) {
        console.log("Error occurred during login:", error);
        // You can also throw the error to propagate it to the caller if needed
        throw error;
    }
}

export async function getAdminProducts() {
    const response = await axios.get(base_url + "products");
    return response.data.result;
}

export async function updateProduct(id, updatedProduct) {
    const response = await axios({
        method: "PUT",
        url: base_url + `updateProduct/${id}`,
        data: updatedProduct,
        headers: { "Content-Type": "multipart/form-data" },
    })
    return response.data;
}

export async function addProduct(product) {
    const response = await axios({
        method: "POST",
        url: base_url + "addProduct",
        data: product,
        headers: { "Content-Type": "multipart/form-data" },
    })
    return response.data;
}

export async function getCategoriesBrief() {
    const response = await axios.get(base_url + "categoriesBrief");
    return response.data.result;
}

export async function getAdminCategories() {
    const response = await axios.get(base_url + "allCategories");
    return response.data.result;
}

export async function updateCategory(id, updatedCategory) {
    const response = await axios({
        method: "PUT",
        url: base_url + `updateCategory/${id}`,
        data: updatedCategory,
        headers: { "Content-Type": "multipart/form-data" },
    })
    return response.data;
}

export async function addCategory(category) {
    const response = await axios({
        method: "POST",
        url: base_url + "addCategory",
        data: category,
        headers: { "Content-Type": "multipart/form-data" },
    })
    return response.data;
}

export async function getAddons() {
    const response = await axios.get(base_url + "addOns");
    return response.data.result;
}

export async function addAddon(addon) {
    const response = await axios({
        method: "POST",
        url: base_url + `addAddOn`,
        data: addon,
        headers: { "Content-Type": "multipart/form-data" },
    })
    return response.data;
}

export async function updateAddon(id, updatedAddon) {
    const response = await axios({
        method: "PUT",
        url: base_url + `updateAddOn/${id}`,
        data: updatedAddon,
        headers: { "Content-Type": "multipart/form-data" },
    })
    return response.data;
}

export async function getTasteProfiles() {
    const response = await axios.get(base_url + "tasteProfiles");
    return response.data.result;
}

export async function addTasteProfile(addon) {
    const response = await axios({
        method: "POST",
        url: base_url + `addTasteProfile`,
        data: addon,
        headers: { "Content-Type": "multipart/form-data" },
    })
    return response.data;
}

export async function updateTasteProfile(id, updatedAddon) {
    const response = await axios({
        method: "PUT",
        url: base_url + `updateTasteProfile/${id}`,
        data: updatedAddon,
        headers: { "Content-Type": "multipart/form-data" },
    })
    return response.data;
}

export async function getBusinessInfo() {
    const response = await axios.get(base_url + "currentBusinessInfo");
    return response.data.result;
}

export async function updateBusinessInfo(info) {
    const response = await axios({
        method: "POST",
        url: base_url + `addBusinessInfo`,
        data: info,
        headers: { "Content-Type": "multipart/form-data" },
    })
    return response.data;
}

export async function deleteAddons(addons) {
    const response = await axios.post(base_url + "deleteAddOns", { addons });
    return response.data;
}

export async function deleteIngredients(ingredients) {
    const response = await axios.post(base_url + "deleteIngredients", { ingredients });
    return response.data;
}

export async function deleteGroups(groups) {
    const response = await axios.post(base_url + "deleteGroups", { groups });
    return response.data;
}

export async function deleteTasteProfiles(tasteProfiles) {
    const response = await axios.post(base_url + "deleteTasteProfiles", { tasteProfiles });
    return response.data;
}

export async function deleteCategories(categories) {
    const response = await axios.post(base_url + "deleteCategories", { categories });
    return response.data;
}

export async function deleteProducts(products) {
    const response = await axios.post(base_url + "deleteProducts", { products });
    return response.data;
}

export async function getOrders() {
    const response = await axios.get(base_url + "orders");
    console.log(response.data)
    return response.data.result?.reverse();
}

export async function deleteOrders(orders) {
    const response = await axios.post(base_url + "deleteOrders", { orders });
    return response.data;
}

export async function updateStatus(order_id, status) {
    const response = await axios.put(base_url + `updateStatus/${order_id}`, { status });
    return response.data;
}

export async function getVariants() {
    const response = await axios.get(base_url + "variants");
    return response.data.result;
}

export async function deleteVariants(variants) {
    const response = await axios.post(base_url + `deleteVariants`, { variants })
    return response.data;
}

export async function addVariant(variant) {
    const response = await axios({
        method: "POST",
        url: base_url + `addVariant`,
        data: variant,
        headers: { "Content-Type": "multipart/form-data" },
    })
    return response.data;
}

export async function updateVariant(id, variant) {
    const response = await axios({
        method: "PUT",
        url: base_url + `updateVariant/${id}`,
        data: variant,
        headers: { "Content-Type": "multipart/form-data" },
    })
    return response.data;
}

export async function getAdmins() {
    const response = await axios.get(base_url + "admins");
    return response.data.result;
}

export async function deleteAdmins(admins) {
    const response = await axios.post(base_url + "deleteAdmins", { admins });
    return response.data;
}

export async function addAdmin(obj) {
    const response = await axios.post(base_url + "adminRegister", obj);
    return response.data;
}

export async function getLeafCategories() {
    const response = await axios.get(base_url + "getLeafCategories");
    return response.data.result;
}

export async function getLanguageKey() {
    try {
        const response = await axios.get(base_url + "languageKey");
        return response.data.result;
    } catch (error) {
        console.log("Error in getLanguageKey:", error);
        throw error;
    }
}

export async function deleteLanguageKeys(languageKeys) {
    try {
        const response = await axios.post(base_url + "deleteLanguageKeys", { languageKeys });
        return response.data;
    } catch (error) {
        console.log("Error in deleteLanguageKey:", error);
        throw error;
    }
}

export async function addLanguageKey(obj) {
    try {
        const response = await axios.post(base_url + "addLanguageKey", obj);
        return response.data;
    } catch (error) {
        console.log("Error in addLanguageKey:", error);
        throw error;
    }
}

export async function updateLanguageKey(languageKey) {
    const response = await axios({
        method: "PUT",
        url: base_url + "updateLanguageKey",
        data: languageKey,

    })
    return response.data;
}

export async function getLanguage() {
    try {
        const response = await axios.get(base_url + "language");
        return response.data.result;
    } catch (error) {
        console.log("Error in getLanguage:", error);
        throw error;
    }
}

export async function deleteLanguages(languageIds) {
    try {
        const response = await axios.post(base_url + "deleteLanguages", { languageIds });
        return response.data;
    } catch (error) {
        console.log("Error in deleteLanguage:", error);
        throw error;
    }
}

export async function addLanguage(obj) {
    try {
        const reqValue = {
            name: obj.name,
        };
        const response = await axios.get(base_url + "addLanguage", { params: reqValue });
        return response.data;
    } catch (error) {
        console.log("Error in addLanguage:", error);
        throw error;
    }
}

export async function updateLanguage(updatedLanguage) {
    const response = await axios({
        method: "PUT",
        url: base_url + "updateLanguage",
        data: updatedLanguage,
    })
    return response.data;
}

export async function updateProductLanguage(updateProductLanguage) {
    const response = await axios({
        method: "PUT",
        url: base_url + "updateProductLanguage",
        data: updateProductLanguage,
    })
    return response.data;
}

export async function updateCategoryLanguage(updateCategoryLanguage) {
    const response = await axios({
        method: "PUT",
        url: base_url + "updateCategoryLanguage",
        data: updateCategoryLanguage,
    })
    return response.data;
}


export async function EditLanguage(id) {
    try {
        const response = await axios.get(base_url + `language/${id}`);
        return response.data;
    } catch (error) {
        console.log("Error in getLanguage:", error);
        throw error;
    }
}

export async function EditProductLanguage(id) {
    try {
        const response = await axios.get(base_url + `productLanguage/${id}`);
        return response.data;
    } catch (error) {
        console.log("Error in getLanguage:", error);
        throw error;
    }
}

export async function EditCategoriesLanguage(id) {
    try {
        const response = await axios.get(base_url + `categoriesLanguage/${id}`);
        return response.data;
    } catch (error) {
        console.log("Error in getLanguage:", error);
        throw error;
    }
}

export async function EditAddonsLanguage(id) {
    try {
        const response = await axios.get(base_url + `addonsLanguage/${id}`);
        return response.data;
    } catch (error) {
        console.log("Error in getLanguage:", error);
        throw error;
    }
}

export async function EditIngredientsLanguage(id) {
    try {
        const response = await axios.get(base_url + `ingredientsLanguage/${id}`);
        return response.data;
    } catch (error) {
        console.log("Error in getLanguage:", error);
        throw error;
    }
}


export async function EditGroupsLanguage(id) {
    try {
        const response = await axios.get(base_url + `groupsLanguage/${id}`);
        return response.data;
    } catch (error) {
        console.log("Error in getLanguage:", error);
        throw error;
    }
}


export async function updateAddonsLanguage(updateAddonsLanguage) {
    const response = await axios({
        method: "PUT",
        url: base_url + "updateAddonsLanguage",
        data: updateAddonsLanguage,
    })
    return response.data;
}

export async function updateIngredientsLanguage(updateIngredientsLanguage) {
    const response = await axios({
        method: "PUT",
        url: base_url + "updateIngredientsLanguage",
        data: updateIngredientsLanguage,
    })
    return response.data;
}

export async function updateGroupsLanguage(updateGroupsLanguage) {
    const response = await axios({
        method: "PUT",
        url: base_url + "updateGroupsLanguage",
        data: updateGroupsLanguage,
    })
    return response.data;
}

export async function EditVariantsLanguage(id) {
    try {
        const response = await axios.get(base_url + `variantsLanguage/${id}`);
        return response.data;
    } catch (error) {
        console.log("Error in getLanguage:", error);
        throw error;
    }
}

export async function updateVariantsLanguage(updateVariantsLanguage) {
    const response = await axios({
        method: "PUT",
        url: base_url + "updateVariantsLanguage",
        data: updateVariantsLanguage,
    })
    return response.data;
}

export const getTemplates = async () => {
    const response = await axios.get(base_url + "cssTemplates")
    return response.data.data;
}

export const updateTemplate = async (filename) => {
    const response = await axios.post(base_url + "selectTemplate", { filename })
    return response.data;
}

export const uploadTemplate = async (form) => {
    const response = await axios({
        method: "POST",
        url: base_url + `addTemplate`,
        data: form,
        headers: { "Content-Type": "multipart/form-data" },
    })
    return response.data;
}

export const getSelectedFilename = async () => {
    const response = await axios.get(base_url + 'getSelectedFilename')
    return response.data.data;
}

export const getIngredients = async () => {
    const response = await axios.get(base_url + 'ingredients')
    console.log(response.data);
    return response.data.result;
}

export async function addIngredient(data) {
    const response = await axios({
        method: "POST",
        url: base_url + `addIngredient`,
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
    })
    return response.data;
}

export const addIngredientsToProduct = async (product_id, ingredientIds) => {
    const response = await axios.post(base_url + `addIngredientsToProduct/${product_id}`, { ingredientIds })
    return response.data;
}

export const getGroups = async () => {
    const response = await axios.get(base_url + 'groups')
    return response.data.result;
}

export const addGroup = async (body) => {
    const response = await axios.post(base_url + 'addGroup', body)
    return response.data;
}

export const updateGroup = async (id, body) => {
    const response = await axios.put(base_url + `updateGroup/${id}`, body)
    return response.data;
}

export const updateIngredient = async (id, body) => {
    const response = await axios({
        method: "PUT",
        url: base_url + `updateIngredient/${id}`,
        data: body,
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
}

export const getProductsByGroup = async (group_id, title = "") => {
    const response = await axios.get(base_url + `getProductByGroup/${group_id}`)
    return response.data?.result;
}

export const pairProductsToGroup = async (product_id, group_id, status) => {
    const response = await axios.put(base_url + `pairProductsToGroup/${product_id}/${group_id}/${status}`)
    return response.data;
}

export const forceTranslations = async () => {
    const response = await axios.get(forceTranslationsApi);
    return response.data.code;
}